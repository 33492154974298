import { Component, BaseComponent, Prop, namespace } from '@zento-lib/components';
import { FormatCurrency } from '@zento-lib/components/i18n/FormatCurrency';
import type { I18nMessage } from '@zento-lib/components/Base/types';
import { StoreConfig } from 'theme/stores/storeConfig/storeConfig';
import type { IProductState } from 'theme/@types/vsf/stores/product';
import { IVSFUser, KEY as UserKey } from 'theme/@types/vsf/stores/user';

import type { IProductPrice } from './Price.d';
import style from './style.scss';

type TierPrice = {
  customer_group_id: number;
  qty: number;
  value: number;
  extension_attributes?: {
    website_id: number;
  };
};

const uiUser = namespace<IVSFUser>(UserKey);

// Defines the different style types for MSRP price display
export type MsrpPriceDisplay = 'cart' | 'checkout';

/**
 * Product Price
 *
 * Renders product price information (also takes in consideration special price and cart product price)
 */
@Component({})
export class ProductPrice extends BaseComponent<IProductPrice, unknown> {
  protected static T = {
    msrpPrice: 'msrp_price',
    msrpPriceLabel: 'msrp_price_label',
    qtyIncrementMessage: 'qty_increment_message',
    regularPrice: 'regular_price',
    smallestPriceMessage: 'smallest_price_message',
    showMsrpLabel: 'show_msrp_label',
  };

  /**
   * Current product
   */
  @Prop({ type: Object, required: true })
  product: IProductState | any;

  /**
   * A value which will render a different css
   */
  @Prop({ type: Boolean, default: false })
  productBoxPrice?: boolean;

  /**
   * Checks if current product has special price
   */
  @Prop({ type: Boolean, default: false })
  showPriceDiscount?: boolean;

  /**
   * A text representing special price discount label
   */
  @Prop({ type: [Object, String], default: '' })
  productPriceDiscountLabel?: I18nMessage;

  /**
   * A text representing including price label
   */
  @Prop({ type: [Object, String], default: '' })
  productPriceInclLabel?: I18nMessage;

  /**
   * A text representing excluding price label
   */
  @Prop({ type: [Object, String], default: '' })
  productPriceExclLabel?: I18nMessage;

  /**
   * Determines qty increment for listing
   */
  @Prop({ type: Boolean, default: false })
  qtyIncrement?: boolean;

  /**
   * Determines qty increment for cart
   */
  @Prop({ type: Boolean, default: false })
  cartQtyIncrement?: boolean;

  /**
   * Determines qty increment for cart
   */
  @Prop({ type: Number, required: false, default: 0 })
  cartTaxdisplayType?: number;

  /**
   * Determines MSRP price display
   */
  @Prop({ type: String, default: '' })
  displayMsrp?: MsrpPriceDisplay;

  /**
   * Determines widgets price display
   */
  @Prop({ type: Boolean, default: false })
  widgets?: boolean;

  protected storeConfigData = new StoreConfig();

  @uiUser.Getter('isLoggedIn')
  protected isLoggedIn: boolean;

  data() {
    return {
      groupPrices: {},
      enableMsrp: true,
    };
  }

  /**
   * Determine product qty
   */
  get productQty(): number {
    return this.product.qty ?? 1;
  }

  private isSpecialPriceActive(fromDate, toDate) {
    const now = new Date();
    fromDate = fromDate ? new Date(fromDate) : false;
    toDate = toDate ? new Date(toDate) : false;

    if (fromDate && toDate) {
      return fromDate < now && toDate > now;
    }

    if (fromDate && !toDate) {
      return fromDate < now;
    }

    if (!fromDate && toDate) {
      return toDate > now;
    }
  }

  /**
   * Determine product original price without tax
   */
  private price(qty: number) {
    const product = this.product;

    if (
      this.extended.$config.zento.theme.price.usePriceTiers &&
      'tier_prices' in product &&
      product.tier_prices.length
    ) {
      const tierPrice = (product.tier_prices as TierPrice[]).reduce((ret, tier) => {
        if (qty >= tier.qty) {
          ret = tier;
        }

        return ret;
      }, {} as TierPrice)?.value;

      if (typeof tierPrice !== 'undefined' && tierPrice > 0) {
        return tierPrice * qty;
      } else {
        return parseFloat(product.regular_price || product.price) * qty;
      }
    }

    if (product.type_id === 'configurable' && 'min_price' in product) {
      if (product.regular_price !== undefined) {
        if (parseFloat(product.min_price) < parseFloat(product.regular_price)) {
          return parseFloat(product.regular_price) * qty;
        } else {
          if (
            product.finalPrice !== undefined &&
            product.finalPrice > 0 &&
            parseFloat(product.finalPrice) === parseFloat(product.regular_price)
          ) {
            return parseFloat(product.regular_price) * qty;
          } else {
            return parseFloat(product.max_price) * qty;
          }
        }
      } else if (product.price !== undefined) {
        if (parseFloat(product.min_price) < parseFloat(product.price)) {
          return parseFloat(product.price) * qty;
        } else {
          return parseFloat(product.max_price) * qty;
        }
      }
    } else if (product.type_id === 'bundle') {
      return this.bundlePrice.productPrice * qty;
    }

    return parseFloat(product.original_price || product.price) * qty;
  }

  /**
   * Determine product original price without tax
   */
  get priceBox() {
    return this.price(this.qtyIncrement && !this.cartQtyIncrement ? 1 : this.productQty);
  }

  /**
   * Determine product original price per unit without tax
   */
  get priceBoxPerUnit() {
    return this.price(1);
  }

  /**
   * Determine product original price with tax
   */
  private priceIncl(qty: number) {
    const product = this.product;

    if (product.type_id === 'configurable' && 'min_price_incltax' in product) {
      if (product.regular_price_incltax !== undefined) {
        if (parseFloat(product.min_price_incltax) < parseFloat(product.regular_price_incltax)) {
          return parseFloat(product.regular_price_incltax) * qty;
        } else {
          if (parseFloat(product.final_price_incltax) === parseFloat(product.regular_price_incltax)) {
            return parseFloat(product.regular_price_incltax) * qty;
          } else {
            return parseFloat(product.max_price_incltax) * qty;
          }
        }
      } else if (product.price_incltax !== undefined) {
        if (parseFloat(product.min_price_incltax) < parseFloat(product.price_incltax)) {
          return parseFloat(product.price_incltax) * qty;
        } else {
          return parseFloat(product.max_price_incltax) * qty;
        }
      }

      return 0;
    } else if (product.type_id === 'bundle') {
      return this.bundlePrice.productPriceIncl * qty;
    }

    return parseFloat(product.price_incltax || product.final_price_incltax) * qty;
  }

  /**
   * Determine product original price with tax
   */
  get priceInclBox() {
    return this.priceIncl(this.qtyIncrement && !this.cartQtyIncrement ? 1 : this.productQty);
  }

  /**
   * Determine product original price per unit with tax
   */
  get priceInclBoxPerUnit() {
    return this.priceIncl(1);
  }

  /**
   * Determine product special price without tax
   */
  private specialPrice(qty: number) {
    const product = this.product;

    if (this.priceDiscount === 0 && this.bundlePrice.productSpecialPrice === 0) {
      return 0;
    } else if (
      this.priceDiscount === 0 &&
      'special_from_date' in product &&
      product.special_from_date !== null &&
      !this.isSpecialPriceActive(product.special_from_date, product.special_to_date)
    ) {
      return 0;
    }

    if (product.type_id === 'configurable' && 'min_price' in product) {
      if (product.regular_price !== undefined) {
        if (parseFloat(product.min_price) < parseFloat(product.regular_price)) {
          return parseFloat(product.min_price) * qty;
        } else {
          return parseFloat(product.max_price) * qty;
        }
      } else if (product.price !== undefined) {
        if (parseFloat(product.min_price) < parseFloat(product.price)) {
          return parseFloat(product.min_price) * qty;
        } else {
          return parseFloat(product.max_price) * qty;
        }
      }
    } else if (product.type_id === 'bundle') {
      return this.bundlePrice.productSpecialPrice * qty;
    }

    if (parseFloat(product.final_price) < parseFloat(product.original_price || product.price)) {
      return parseFloat(product.final_price) * qty;
    } else {
      const specialPrice =
        product.specialPrice !== undefined || product.special_price !== undefined
          ? parseFloat(product.specialPrice || product.special_price)
          : 0;

      return specialPrice * qty;
    }
  }

  /**
   * Determine product special price without tax
   */
  get specialPriceBox() {
    return this.specialPrice(this.qtyIncrement && !this.cartQtyIncrement ? 1 : this.productQty);
  }

  /**
   * Determine product special price per unit without tax
   */
  get specialPricePerUnit() {
    return this.specialPrice(1);
  }

  /**
   * Determine product special price including tax
   */
  private specialPriceIncl(qty: number) {
    const product = this.product;

    if (this.priceDiscount === 0 && this.bundlePrice.productSpecialPriceIncl === 0) {
      return 0;
    } else if (
      this.priceDiscount === 0 &&
      'special_from_date' in product &&
      product.special_from_date !== null &&
      !this.isSpecialPriceActive(product.special_from_date, product.special_to_date)
    ) {
      return 0;
    }

    if (product.type_id === 'configurable' && 'min_price_incltax' in product) {
      if (product.regular_price_incltax !== undefined) {
        if (parseFloat(product.min_price_incltax) < parseFloat(product.regular_price_incltax)) {
          return parseFloat(product.min_price_incltax) * qty;
        } else {
          return parseFloat(product.max_price_incltax) * qty;
        }
      } else if (product.price_incltax !== undefined) {
        if (parseFloat(product.min_price_incltax) < parseFloat(product.price_incltax)) {
          return parseFloat(product.min_price_incltax) * qty;
        } else {
          return parseFloat(product.max_price_incltax) * qty;
        }
      }
    } else if (product.type_id === 'bundle') {
      return this.bundlePrice.productSpecialPriceIncl * qty;
    }

    if (
      parseFloat(product.final_price_incltax) < parseFloat(product.original_price_incl_tax || product.price_incltax)
    ) {
      return parseFloat(product.final_price_incltax) * qty;
    } else {
      const specialPrice =
        product.specialPriceInclTax !== undefined || product.special_price_incltax !== null
          ? parseFloat(product.specialPriceInclTax || product.special_price_incltax)
          : 0;

      return specialPrice * qty;
    }
  }

  /**
   * Determine product special price including tax
   */
  get specialPriceInclBox() {
    return this.specialPriceIncl(this.qtyIncrement && !this.cartQtyIncrement ? 1 : this.productQty);
  }

  /**
   * Determine product special price per unit including tax
   */
  get specialPriceInclPerUnit() {
    return this.specialPriceIncl(1);
  }

  /**
   * Determine current product discount in percent
   */
  get discountPercentage() {
    const product = this.product;
    const actualPrice =
      product.type_id === 'bundle' ? this.bundlePrice.productPrice : product.originalPriceInclTax ?? product.price;
    const specialPrice =
      product.type_id === 'bundle'
        ? this.bundlePrice.productSpecialPrice
        : product.finalPriceInclTax
        ? product.finalPriceInclTax
        : product.priceInclTax
        ? product.priceInclTax
        : product.final_price;
    let priceDiscountPercentage = '';

    if (
      specialPrice &&
      actualPrice &&
      parseFloat(actualPrice as any) !== 0 &&
      parseFloat(specialPrice as any) !== 0 &&
      parseFloat(specialPrice as any) !== parseFloat(actualPrice as any)
    ) {
      let discount = parseFloat((100 - ((specialPrice as any) / actualPrice) * 100).toPrecision(3));
      discount = Math.round(discount);

      if (isFinite(discount) && discount > 0) {
        priceDiscountPercentage = discount + '%';
      }
    } else if (product.type_id === 'bundle') {
      const discount = parseFloat((100 - product.original_special_price).toPrecision(2));

      if (isFinite(discount)) {
        priceDiscountPercentage = discount + '%';
      }
    }

    return priceDiscountPercentage;
  }

  /**
   * Determines price discount
   */
  get priceDiscount() {
    const product = this.product;
    let priceDiscount = 0;

    this.userGroupPrices();

    if (product.type_id === 'bundle') {
      priceDiscount = (this.bundlePrice.productPrice - this.bundlePrice.productSpecialPrice) * this.productQty;
    } else if (
      product.type_id === 'configurable' &&
      'min_price' in product &&
      (product.regular_price || product.price) > product.min_price
    ) {
      priceDiscount =
        product.min_price &&
        product.final_price &&
        parseFloat(product.final_price || product.min_price) < parseFloat(product.regular_price || product.price)
          ? (parseFloat(product.final_price || product.min_price) -
              parseFloat(product.regular_price || product.price)) *
            this.productQty
          : 0;
    } else {
      priceDiscount =
        (product.regular_price || product.price) &&
        product.final_price &&
        parseFloat(product.final_price) < parseFloat(product.regular_price || product.price)
          ? (parseFloat(product.regular_price || product.price) - parseFloat(product.final_price)) * this.productQty
          : 0;
    }

    return priceDiscount;
  }

  /**
   * Determine price for bundle products
   */
  private get bundlePrice() {
    const product = this.product;
    const minPrice = parseFloat(product.min_price) || 0;
    const maxPrice = parseFloat(product.max_price) || 0;
    const minPriceIncltax = parseFloat(product.min_price_incltax) || 0;
    const maxPriceIncltax = parseFloat(product.max_price_incltax) || 0;

    let productPrice = 0;
    let productPriceIncl = 0;
    let productSpecialPrice = 0;
    let productSpecialPriceIncl = 0;

    if ('bundle_options' in product) {
      const stockStatus =
        typeof product.stock.stock_status === 'number'
          ? product.stock.stock_status === 1
          : product.stock.stock_status === '1';

      if (
        stockStatus &&
        ((maxPrice.toFixed(2) !== minPrice.toFixed(2) && minPrice > 0) ||
          (maxPriceIncltax.toFixed(2) !== minPriceIncltax.toFixed(2) && minPriceIncltax > 0) ||
          ((product.regular_price || parseFloat(product.price).toFixed(2)) > minPrice && minPrice > 0))
      ) {
        productSpecialPrice = minPrice || product.regular_price || product.price;
        productSpecialPriceIncl = minPriceIncltax || product.regular_price_incltax || product.price_incltax;
      }

      if (this.widgets || ((product.regular_price || product.price) >= maxPrice && stockStatus)) {
        if (
          (maxPrice.toFixed(2) || product.regular_price || product.price.toFixed(2) || product.final_price) >=
          minPrice.toFixed(2)
        ) {
          productPrice =
            minPrice !== maxPrice
              ? minPrice
              : product.regular_price || product.price || product.originalPrice || product.final_price;
          productPriceIncl =
            product.regular_price_incltax ||
            product.priceInclTax ||
            product.originalPriceInclTax ||
            product.finalPriceInclTax ||
            minPriceIncltax;
        }
      } else {
        if (
          (maxPrice.toFixed(2) || product.regular_price || product.price.toFixed(2) || product.final_price) >=
          minPrice.toFixed(2)
        ) {
          product.bundle_options.map((bo) => {
            let defaultOption = bo.product_links.find((p) => {
              return p.is_default;
            });
            if (!defaultOption) defaultOption = bo.product_links[0];

            bo.product_links.map((pl) => {
              if (pl.is_default && 'price_data' in pl) {
                const plPrices = pl.price_data;
                if ((plPrices.price || plPrices.price_incltax) > 0) {
                  if (this.isLoggedIn && 'price_groups' in this.product) {
                    const userGroupId = this.$store.state.user.current.group_id;
                    const groupId = Object.keys(this.product.price_groups).find((p) => p === `group_${userGroupId}`);

                    if (groupId) {
                      const userGroupPrices = plPrices.price_groups[groupId];

                      productPrice += parseFloat(userGroupPrices.regular_price);
                      productPriceIncl += parseFloat(userGroupPrices.price_incltax);
                    }
                  } else {
                    productPrice += parseFloat(plPrices.price);
                    productPriceIncl += parseFloat(plPrices.price_incltax);
                  }
                }
              }
            });
          });
        }

        if (productPrice > minPrice && minPrice > 0) {
          productSpecialPrice = minPrice;
          productSpecialPriceIncl = minPriceIncltax;
        }
      }
    }

    return {
      productPrice,
      productSpecialPrice,
      productPriceIncl,
      productSpecialPriceIncl,
    };
  }

  /**
   * Determine price unit for cart products
   */
  private get cartIncrement() {
    if ('stock' in this.product) {
      if (
        'qty_increments' in this.product.stock &&
        parseFloat(this.product.stock.qty_increments) >= 1 &&
        !this.cartQtyIncrement
      ) {
        return true;
      }

      return (
        'qty_increments' in this.product.stock &&
        parseFloat(this.product.stock.qty_increments) <= 1 &&
        this.cartQtyIncrement
      );
    }

    return false;
  }

  /**
   * Determine MSRP price display
   */
  private get msrpPriceDisplay() {
    const product = this.product;
    const msrp = 'msrp' in product && parseFloat(product.msrp) > 0 && this.specialPriceBox === 0;

    if (msrp && product.msrp_display_actual_price_type === '0') {
      return true;
    } else if (msrp && product.msrp_display_actual_price_type === '1') {
      this.$data.enableMsrp = false;

      return true;
    } else if (msrp && product.msrp_display_actual_price_type === '2' && this.displayMsrp === 'cart') {
      return true;
    } else if (msrp && product.msrp_display_actual_price_type === '3' && this.displayMsrp === 'checkout') {
      return true;
    }

    return false;
  }

  /**
   * Enable MSRP price display
   */
  private toggleMsrp() {
    this.$data.enableMsrp = !this.$data.enableMsrp;
  }

  private parseJSONObject(jsonString) {
    try {
      const o = JSON.parse(jsonString);

      if (o && typeof o === 'object') {
        return o;
      }
    } catch (e) {}

    return jsonString;
  }

  private userGroupPrices() {
    if (this.isLoggedIn && 'price_groups' in this.product) {
      const product = this.product;
      const userGroupId = this.$store.state.user.current.group_id;
      const priceGroups = this.parseJSONObject(product.price_groups);
      const groupId = Object.keys(priceGroups).find((p) => p === `group_${userGroupId}`);

      if (groupId) {
        const userGroupPrices = priceGroups[groupId];

        if (userGroupPrices.final_price > 0) {
          product.finalPrice = parseFloat(userGroupPrices.final_price);
          product.final_price = parseFloat(userGroupPrices.final_price);
        }
        if (userGroupPrices.final_price_incltax > 0) {
          product.finalPriceInclTax = parseFloat(userGroupPrices.final_price_incltax);
          product.final_price_incl_tax = parseFloat(userGroupPrices.final_price_incltax);
        }
        if (userGroupPrices.max_price > 0) {
          product.max_price = userGroupPrices.max_price;
        }
        if (userGroupPrices.max_price_incltax > 0) {
          product.max_price_incltax = userGroupPrices.max_price_incltax;
        }
        if (userGroupPrices.min_price > 0) {
          product.min_price = userGroupPrices.min_price;
        }
        if (userGroupPrices.min_price_incltax > 0) {
          product.min_price_incltax = userGroupPrices.min_price_incltax;
        }
        if (userGroupPrices.minimal_price_incltax > 0) {
          product.minimal_price_incltax = userGroupPrices.minimal_price_incltax;
        }
        if (userGroupPrices.price_incltax > 0) {
          product.price_incltax = userGroupPrices.price_incltax;
        }
        if (userGroupPrices.regular_price > 0) {
          product.regular_price = userGroupPrices.regular_price;
        }
        if (userGroupPrices.regular_price_incltax > 0) {
          product.regular_price_incltax = userGroupPrices.regular_price_incltax;
        }
        if (userGroupPrices.special_price_incltax > 0) {
          product.special_price_incltax = userGroupPrices.special_price_incltax;
        }
        if (parseFloat(userGroupPrices.final_price) < parseFloat(userGroupPrices.price)) {
          product.min_price = parseFloat(userGroupPrices.final_price);
          product.min_price_incltax = parseFloat(userGroupPrices.final_price_incl_tax);
          product.specialPrice = parseFloat(userGroupPrices.final_price);
          product.specialPriceInclTax = parseFloat(userGroupPrices.final_price_incl_tax);
          product.special_price_incltax = parseFloat(userGroupPrices.final_price_incl_tax);
        }
      }
    }
  }

  beforeMount() {
    this.userGroupPrices();

    this.onBroadcast('user-after-loggedin', () => {
      this.userGroupPrices();
    });
  }

  render() {
    const priceConf = this.extended.$config.zento.theme.price;
    const product = this.product;
    const taxdisplayType = this.storeConfigData.storeConfigurations.taxDisplayType ?? 0;

    return taxdisplayType !== 0 && product.type_id !== 'grouped' && this.priceBox ? (
      <div class={style.priceWrapper}>
        {this.msrpPriceDisplay ? (
          <div class={style.msrpPriceBox}>
            {product.msrp_display_actual_price_type === '1' ? (
              <strong class={style.showMsrp} onClick={this.toggleMsrp}>
                {this.getTranslation({ id: ProductPrice.T.showMsrpLabel })}
              </strong>
            ) : null}

            {this.$data.enableMsrp ? (
              <div
                class={style.prpPrice}
                key={product.id + '-msrp-price'}
                data-tooltip={this.getTranslation({ id: ProductPrice.T.msrpPriceLabel })}>
                <span>{this.getTranslation({ id: ProductPrice.T.msrpPrice })}</span>
                <FormatCurrency
                  class={[style.price, style.originalPrice]}
                  value={parseFloat(product.msrp) * (this.qtyIncrement && !this.cartQtyIncrement ? 1 : this.productQty)}
                  key='product-msrp'
                />
              </div>
            ) : null}
          </div>
        ) : null}

        {priceConf.customPriceDisplay ? ( // TODO: Temporary option for protectstore instance (task "ZENSP-21"). Should be removed after proper verification on other instances.
          <div>
            {(('final_price_incltax' in product &&
              parseFloat(product.final_price || product.price) !== parseFloat(product.final_price_incltax) &&
              this.priceInclBox > 0 &&
              this.priceBox !== this.priceInclBox) ||
              (this.priceInclBox > 0 && this.priceBox !== this.priceInclBox)) &&
            ((!this.cartTaxdisplayType && taxdisplayType !== 1) ||
              (this.cartTaxdisplayType && this.cartTaxdisplayType !== 1)) ? (
              <div class={style.priceInclTax}>
                {product.stock &&
                product.stock?.qty_increments &&
                parseFloat(product.stock?.qty_increments) > 1 &&
                !this.qtyIncrement ? (
                  <p class={style.pricePerUnit}>
                    <span>
                      <FormatCurrency
                        value={
                          this.specialPriceBox > 0 && this.specialPriceBox < this.priceBox
                            ? this.specialPriceInclPerUnit
                            : this.priceInclBoxPerUnit
                        }
                        measure={!this.qtyIncrement && 'salesuom' in product ? product.salesuom : ''}
                      />
                    </span>
                  </p>
                ) : null}

                {priceConf.enableLabel ? (
                  <span class={style.label}>{this.getTranslation(this.productPriceInclLabel)}</span>
                ) : null}

                <div class={{ [style.priceBox]: priceConf.priceOrder }}>
                  {priceConf.enableMsrpLabel &&
                  this.specialPriceInclBox > 0 &&
                  this.specialPriceInclBox < this.priceInclBox ? (
                    <div
                      class={style.prpPrice}
                      key={product.id + '-prp-price'}
                      data-tooltip={this.getTranslation({ id: ProductPrice.T.msrpPriceLabel })}>
                      <span>{this.getTranslation({ id: ProductPrice.T.msrpPrice })}</span>
                      <FormatCurrency
                        value={this.priceInclBox}
                        measure={
                          this.specialPriceInclBox <= this.priceInclBox && this.qtyIncrement && 'salesuom' in product
                            ? this.cartIncrement
                              ? product.salesuom
                              : ''
                            : ''
                        }
                        class={{
                          [style.price]: !this.productBoxPrice,
                          [style.originalPrice]:
                            !this.productBoxPrice &&
                            this.specialPriceInclBox > 0 &&
                            this.specialPriceInclBox < this.priceInclBox,
                          [style.productBoxPrice]:
                            this.productBoxPrice &&
                            (this.specialPriceInclBox < 1 || this.specialPriceInclBox === this.priceInclBox),
                          [style.productBoxOriginalPrice]:
                            this.productBoxPrice &&
                            this.specialPriceInclBox > 0 &&
                            this.specialPriceInclBox < this.priceInclBox,
                        }}
                      />
                    </div>
                  ) : (
                    <FormatCurrency
                      value={this.priceInclBox}
                      measure={
                        this.specialPriceInclBox <= this.priceInclBox && this.qtyIncrement && 'salesuom' in product
                          ? this.cartIncrement
                            ? product.salesuom
                            : ''
                          : ''
                      }
                      class={{
                        [style.price]: !this.productBoxPrice,
                        [style.originalPrice]:
                          !this.productBoxPrice &&
                          this.specialPriceInclBox > 0 &&
                          this.specialPriceInclBox < this.priceInclBox,
                        [style.productBoxPrice]:
                          this.productBoxPrice &&
                          (this.specialPriceInclBox < 1 || this.specialPriceInclBox === this.priceInclBox),
                        [style.productBoxOriginalPrice]:
                          this.productBoxPrice &&
                          this.specialPriceInclBox > 0 &&
                          this.specialPriceInclBox < this.priceInclBox,
                      }}
                    />
                  )}

                  {this.specialPriceInclBox > 0 && this.specialPriceInclBox < this.priceInclBox ? (
                    <FormatCurrency
                      value={this.specialPriceInclBox}
                      measure={
                        this.qtyIncrement && 'salesuom' in product ? (this.cartIncrement ? product.salesuom : '') : ''
                      }
                      class={{
                        [style.specialPrice]: !this.productBoxPrice,
                        [style.productBoxSpecialPrice]: this.productBoxPrice,
                      }}
                    />
                  ) : null}
                </div>
              </div>
            ) : null}

            {(('final_price_incltax' in product &&
              parseFloat(product.final_price || product.price) !== parseFloat(product.final_price_incltax) &&
              this.priceInclBox > 0 &&
              this.priceBox !== this.priceInclBox) ||
              (this.priceInclBox > 0 && this.priceBox !== this.priceInclBox)) &&
            ((!this.cartTaxdisplayType && taxdisplayType !== 2) ||
              (this.cartTaxdisplayType && this.cartTaxdisplayType !== 2)) ? (
              <div>
                {product.stock &&
                product.stock?.qty_increments &&
                parseFloat(product.stock?.qty_increments) > 1 &&
                !this.qtyIncrement ? (
                  <p class={style.pricePerUnit}>
                    <span>
                      <FormatCurrency
                        value={this.priceBoxPerUnit}
                        measure={!this.qtyIncrement && 'salesuom' in product ? product.salesuom : ''}
                      />
                    </span>
                  </p>
                ) : null}

                {priceConf.enableLabel ? (
                  <span class={style.label}>{this.getTranslation(this.productPriceExclLabel)}</span>
                ) : null}

                <div class={{ [style.priceBox]: priceConf.priceOrder }}>
                  {priceConf.usePriceTiers &&
                  'tier_prices' in product &&
                  product.tier_prices.length &&
                  product.regular_price * this.productQty > this.priceBox ? (
                    <p class={style.pricePerUnit}>
                      {this.getTranslation({ id: ProductPrice.T.regularPrice })}
                      <span>
                        <FormatCurrency value={product.regular_price} />
                      </span>
                    </p>
                  ) : null}

                  {priceConf.enableMsrpLabel && this.specialPriceBox > 0 && this.specialPriceBox < this.priceBox ? (
                    <div
                      class={style.prpPrice}
                      key={product.id + '-prp-price'}
                      data-tooltip={this.getTranslation({ id: ProductPrice.T.msrpPriceLabel })}>
                      <span>{this.getTranslation({ id: ProductPrice.T.msrpPrice })}</span>
                      <FormatCurrency
                        value={this.priceBox}
                        measure={
                          this.specialPriceBox <= this.priceBox && this.qtyIncrement && 'salesuom' in product
                            ? this.cartIncrement
                              ? product.salesuom
                              : ''
                            : ''
                        }
                        class={{
                          [style.price]: !this.productBoxPrice,
                          [style.originalPrice]:
                            !this.productBoxPrice && this.specialPriceBox > 0 && this.specialPriceBox < this.priceBox,
                          [style.productBoxPrice]:
                            this.productBoxPrice &&
                            (this.specialPriceBox < 1 || this.specialPriceBox === this.priceBox),
                          [style.productBoxOriginalPrice]:
                            this.productBoxPrice && this.specialPriceBox > 0 && this.specialPriceBox < this.priceBox,
                        }}
                      />
                    </div>
                  ) : (
                    <FormatCurrency
                      value={this.priceBox}
                      measure={
                        this.specialPriceBox <= this.priceBox && this.qtyIncrement && 'salesuom' in product
                          ? this.cartIncrement
                            ? product.salesuom
                            : ''
                          : ''
                      }
                      class={{
                        [style.price]: !this.productBoxPrice,
                        [style.originalPrice]:
                          !this.productBoxPrice && this.specialPriceBox > 0 && this.specialPriceBox < this.priceBox,
                        [style.productBoxPrice]:
                          this.productBoxPrice && (this.specialPriceBox < 1 || this.specialPriceBox === this.priceBox),
                        [style.productBoxOriginalPrice]:
                          this.productBoxPrice && this.specialPriceBox > 0 && this.specialPriceBox < this.priceBox,
                      }}
                    />
                  )}

                  {this.specialPriceBox > 0 && this.specialPriceBox < this.priceBox ? (
                    <FormatCurrency
                      value={this.specialPriceBox}
                      measure={
                        this.qtyIncrement && 'salesuom' in product ? (this.cartIncrement ? product.salesuom : '') : ''
                      }
                      class={{
                        [style.specialPrice]: !this.productBoxPrice,
                        [style.productBoxSpecialPrice]: this.productBoxPrice,
                      }}
                    />
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div>
            {((product.stock &&
              (product.stock.is_in_stock === '1' || product.stock.is_in_stock) &&
              'final_price_incltax' in product &&
              parseFloat(product.final_price || product.price) !== parseFloat(product.final_price_incltax) &&
              this.priceInclBox > 0 &&
              this.priceBox !== this.priceInclBox) ||
              (this.priceInclBox > 0 && this.priceBox !== this.priceInclBox)) &&
            (taxdisplayType === 3 || this.cartTaxdisplayType === 3) ? (
              <div class={style.priceInclTax}>
                {product.stock &&
                product.stock?.qty_increments &&
                parseFloat(product.stock?.qty_increments) > 1 &&
                !this.qtyIncrement ? (
                  <p class={style.pricePerUnit}>
                    <span>
                      <FormatCurrency
                        value={
                          this.specialPriceBox > 0 && this.specialPriceBox < this.priceBox
                            ? this.specialPriceInclPerUnit
                            : this.priceInclBoxPerUnit
                        }
                        measure={!this.qtyIncrement && 'salesuom' in product ? product.salesuom : ''}
                      />
                    </span>
                  </p>
                ) : null}

                {priceConf.enableLabel ? (
                  <span class={style.label}>{this.getTranslation(this.productPriceInclLabel)}</span>
                ) : null}

                <div class={{ [style.priceBox]: priceConf.priceOrder }}>
                  {priceConf.enableMsrpLabel &&
                  this.specialPriceInclBox > 0 &&
                  this.specialPriceInclBox < this.priceInclBox ? (
                    <div
                      class={style.prpPrice}
                      key={product.id + '-prp-price'}
                      data-tooltip={this.getTranslation({ id: ProductPrice.T.msrpPriceLabel })}>
                      <span>{this.getTranslation({ id: ProductPrice.T.msrpPrice })}</span>
                      <FormatCurrency
                        value={this.priceInclBox}
                        measure={
                          this.specialPriceInclBox <= this.priceInclBox && this.qtyIncrement && 'salesuom' in product
                            ? this.cartIncrement
                              ? product.salesuom
                              : ''
                            : ''
                        }
                        class={{
                          [style.price]: !this.productBoxPrice,
                          [style.originalPrice]:
                            !this.productBoxPrice &&
                            this.specialPriceInclBox > 0 &&
                            this.specialPriceInclBox < this.priceInclBox,
                          [style.productBoxPrice]:
                            this.productBoxPrice &&
                            (this.specialPriceInclBox < 1 || this.specialPriceInclBox === this.priceInclBox),
                          [style.productBoxOriginalPrice]:
                            this.productBoxPrice &&
                            this.specialPriceInclBox > 0 &&
                            this.specialPriceInclBox < this.priceInclBox,
                        }}
                      />
                    </div>
                  ) : (
                    <FormatCurrency
                      value={this.priceInclBox}
                      measure={
                        this.specialPriceInclBox <= this.priceInclBox && this.qtyIncrement && 'salesuom' in product
                          ? this.cartIncrement
                            ? product.salesuom
                            : ''
                          : ''
                      }
                      class={{
                        [style.price]: !this.productBoxPrice,
                        [style.originalPrice]:
                          !this.productBoxPrice &&
                          this.specialPriceInclBox > 0 &&
                          this.specialPriceInclBox < this.priceInclBox,
                        [style.productBoxPrice]:
                          this.productBoxPrice &&
                          (this.specialPriceInclBox < 1 || this.specialPriceInclBox === this.priceInclBox),
                        [style.productBoxOriginalPrice]:
                          this.productBoxPrice &&
                          this.specialPriceInclBox > 0 &&
                          this.specialPriceInclBox < this.priceInclBox,
                      }}
                    />
                  )}

                  {this.specialPriceInclBox > 0 && this.specialPriceInclBox < this.priceInclBox ? (
                    <FormatCurrency
                      value={this.specialPriceInclBox}
                      measure={
                        this.qtyIncrement && 'salesuom' in product ? (this.cartIncrement ? product.salesuom : '') : ''
                      }
                      class={{
                        [style.specialPrice]: !this.productBoxPrice,
                        [style.productBoxSpecialPrice]: this.productBoxPrice,
                      }}
                    />
                  ) : null}
                </div>
              </div>
            ) : null}

            <div>
              {product.stock &&
              product.stock?.qty_increments &&
              parseFloat(product.stock?.qty_increments) > 1 &&
              !this.qtyIncrement ? (
                <p class={style.pricePerUnit}>
                  <span>
                    <FormatCurrency
                      value={this.priceBoxPerUnit}
                      measure={!this.qtyIncrement && 'salesuom' in product ? product.salesuom : ''}
                    />
                  </span>
                </p>
              ) : null}

              {priceConf.enableLabel ? (
                <span class={style.label}>{this.getTranslation(this.productPriceExclLabel)}</span>
              ) : null}

              <div class={{ [style.priceBox]: priceConf.priceOrder }}>
                {priceConf.usePriceTiers &&
                'tier_prices' in product &&
                product.tier_prices.length &&
                product.regular_price * this.productQty > this.priceBox ? (
                  <p class={style.pricePerUnit}>
                    {this.getTranslation({ id: ProductPrice.T.regularPrice })}
                    <span>
                      <FormatCurrency value={product.regular_price} />
                    </span>
                  </p>
                ) : null}

                {priceConf.enableMsrpLabel && this.specialPriceBox > 0 && this.specialPriceBox < this.priceBox ? (
                  <div
                    class={style.prpPrice}
                    key={product.id + '-prp-price'}
                    data-tooltip={this.getTranslation({ id: ProductPrice.T.msrpPriceLabel })}>
                    <span>{this.getTranslation({ id: ProductPrice.T.msrpPrice })}</span>
                    <FormatCurrency
                      value={this.priceBox}
                      measure={
                        this.specialPriceBox <= this.priceBox && this.qtyIncrement && 'salesuom' in product
                          ? this.cartIncrement
                            ? product.salesuom
                            : ''
                          : ''
                      }
                      class={{
                        [style.price]: !this.productBoxPrice,
                        [style.originalPrice]:
                          !this.productBoxPrice && this.specialPriceBox > 0 && this.specialPriceBox < this.priceBox,
                        [style.productBoxPrice]:
                          this.productBoxPrice && (this.specialPriceBox < 1 || this.specialPriceBox === this.priceBox),
                        [style.productBoxOriginalPrice]:
                          this.productBoxPrice && this.specialPriceBox > 0 && this.specialPriceBox < this.priceBox,
                      }}
                    />
                  </div>
                ) : (
                  <FormatCurrency
                    value={this.priceBox}
                    measure={
                      this.specialPriceBox <= this.priceBox && this.qtyIncrement && 'salesuom' in product
                        ? this.cartIncrement
                          ? product.salesuom
                          : ''
                        : ''
                    }
                    class={{
                      [style.price]: !this.productBoxPrice,
                      [style.originalPrice]:
                        !this.productBoxPrice && this.specialPriceBox > 0 && this.specialPriceBox < this.priceBox,
                      [style.productBoxPrice]:
                        this.productBoxPrice && (this.specialPriceBox < 1 || this.specialPriceBox === this.priceBox),
                      [style.productBoxOriginalPrice]:
                        this.productBoxPrice && this.specialPriceBox > 0 && this.specialPriceBox < this.priceBox,
                      [style.specialPrice]: this.msrpPriceDisplay,
                    }}
                  />
                )}

                {this.specialPriceBox > 0 && this.specialPriceBox < this.priceBox ? (
                  <FormatCurrency
                    value={this.specialPriceBox}
                    measure={
                      this.qtyIncrement && 'salesuom' in product ? (this.cartIncrement ? product.salesuom : '') : ''
                    }
                    class={{
                      [style.specialPrice]: !this.productBoxPrice,
                      [style.productBoxSpecialPrice]: this.productBoxPrice,
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
        )}

        {this.showPriceDiscount &&
        this.priceDiscount !== 0 &&
        this.specialPriceBox > 0 &&
        this.specialPriceBox < this.priceBox ? (
          <div class={style.priceDiscount} data-attr='price-discount'>
            {priceConf.showDiscountPercentage ? (
              <span>
                {this.discountPercentage && parseFloat(this.discountPercentage.replace('%', '')) > 0 ? (
                  <span class={[style.priceDiscountValue, style.priceDiscount]}>{this.discountPercentage}</span>
                ) : null}
              </span>
            ) : (
              <div>
                <span key='discount-label'>{this.getTranslation(this.productPriceDiscountLabel)}</span>
                <FormatCurrency
                  value={this.priceDiscount}
                  class={style.priceDiscountValue}
                  key='product-discount-price'
                />
              </div>
            )}
          </div>
        ) : null}

        {product.stock &&
        product.stock?.qty_increments &&
        parseFloat(product.stock?.qty_increments) > 1 &&
        this.qtyIncrement ? (
          <p class={style.qtyIncrenment}>
            {this.getTranslation({
              id: ProductPrice.T.qtyIncrementMessage,
              data: { qty_increnment: product.stock?.qty_increments },
            })}
          </p>
        ) : null}

        {'enable_smallest_price' in product &&
        (product.enable_smallest_price === 1 || product.enable_smallest_price === '1') &&
        'smallest_price' in product ? (
          <span class={style.smallestPrice}>
            {this.getTranslation({ id: ProductPrice.T.smallestPriceMessage })}:
            <FormatCurrency value={parseFloat(product.smallest_price)} key='product-smallest-price' />
          </span>
        ) : null}
      </div>
    ) : (
      <div class={style.priceWrapper} />
    );
  }
}
